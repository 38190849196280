'use client';
import { Link, Button, Card, CardBody, CardFooter, CardHeader, Input, Tab, Tabs } from "@heroui/react";
import { useEffect, useMemo, useRef, useState } from 'react';
import { EyeSlashIcon } from '@/components/icons/EyeSlashIcon';
import { EyeIcon } from '@/components/icons/EyeIcon';
import { InfoIcon } from '@/components/icons/InfoIcon';
import { Loading } from '@/components/Loading';
import { useRouter, useSearchParams } from 'next/navigation';
import EmailVerified from '@/components/EmailVerifiedComponent';
import { useSignIn, useSignUp } from '@clerk/nextjs';
import Logo from '@/components/svgs/Logo';

export default function Login() {
    const router = useRouter();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const searchParams = useSearchParams();
    const redirectUrl = searchParams?.get('redirectUrl');
    const message = searchParams?.get('message');

    const [isVisible, setIsVisible] = useState(false);
    const { isLoaded: isSignInLoaded, signIn, setActive: setSignInActive } = useSignIn();
    const { isLoaded: isSignUpLoaded, signUp, setActive: setSignUpActive } = useSignUp();
    const inputsRef = useRef<(HTMLInputElement | null)[]>([]);
    const toggleVisibility = () => setIsVisible(!isVisible);

    const [pendingVerification, setPendingVerification] = useState(false);
    const [isEmailVerified, setIsEmailVerified] = useState(false);
    const [code, setCode] = useState('');
    const [canResend, setCanResend] = useState(true);
    const [timer, setTimer] = useState(60);

    const [error, setError] = useState('');

    useMemo(() => {
        inputsRef.current = inputsRef.current.slice(0, 6);
        if (code.length == 6) {
            signUp
                ?.attemptEmailAddressVerification({
                    code: code,
                })
                .then(async (result) => {
                    if (result.status === 'complete') {
                        // console.log(result);
                        setPendingVerification(false);
                        await setSignUpActive({ session: result.createdSessionId });
                        AddUserToDb(result);
                        return <EmailVerified />;
                    }
                })
                .catch((err) => {
                    setError(err.errors[0].longMessage);
                });
        }
    }, [code]);

    useEffect(() => {
        if (!canResend) {
            const countdown = setInterval(() => {
                setTimer((prevTimer) => {
                    if (prevTimer === 1) {
                        setCanResend(true);
                        clearInterval(countdown);
                    }
                    return prevTimer > 0 ? prevTimer - 1 : 0;
                });
            }, 1000); // 1 second

            // Cleanup function to clear the timer if the component unmounts
            return () => clearInterval(countdown);
        }
    }, [canResend]);

    if (!isSignInLoaded || !isSignUpLoaded) {
        return <Loading />;
    }

    async function AddUserToDb(result: any) {
        if (result.status === 'complete') {
            await fetch(`${process.env.NEXT_PUBLIC_BASE_URL}/api/auth/postPersonalSignUp`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    user_id: result.createdUserId,
                    firstName: firstname,
                    lastName: lastname,
                    emailAddress: email,
                }),
            });
        }
    }

    async function handleGoogleAuth() {
        if (!isSignInLoaded || !isSignUpLoaded) {
            return;
        }
        // If the user has an existing oauth account but does not yet exist as
        // a user in your app, you can use the transfer method to forward that
        // information.

        const userNeedsToBeCreated = signIn?.firstFactorVerification.status === 'transferable';

        if (userNeedsToBeCreated) {
            const res = await signUp.create({
                transfer: true,
            });
            if (res?.status === 'complete') {
                setSignInActive({
                    session: res.createdSessionId,
                });
            }
        }

        signUp
            ?.create({
                strategy: 'oauth_google',
                redirectUrl: `${process.env.NEXT_PUBLIC_BASE_URL}`,
            })
            .then(async (result) => {
                if (result.status === 'complete') {
                    await setSignInActive({ session: result.createdSessionId });
                    await fetch(process.env.NEXT_PUBLIC_AUTH_API_URL + '/auth/postPersonalSignUp', {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({
                            user_id: result.createdUserId,
                            firstName: firstname,
                            lastName: lastname,
                            emailAddress: email,
                        }),
                    });
                }
            })
            .catch((err) => {
                setError(err.errors[0].longMessage);
                // console.error('error', err.errors[0].longMessage);
            });

        // If the user has an account in your application, but does not yet
        // have an oauth account connected, you can use the transfer method to
        // forward that information.

        const userExistsButNeedsToSignIn =
            signUp?.verifications.externalAccount.status === 'transferable' && signUp?.verifications.externalAccount.error?.code === 'external_account_exists';

        // console.log(userExistsButNeedsToSignIn);
        if (userExistsButNeedsToSignIn) {
            await signIn.create({ transfer: true }).then(async (result) => {
                if (result.status === 'complete') {
                    await setSignInActive({
                        session: result.createdSessionId,
                    });
                }

                // if (result.firstFactorVerification.status === 'unverified') {
                //     setPendingVerification(true);
                //     sendEmailVerification();
                // }
            });
        }

        signIn.authenticateWithRedirect({
            strategy: 'oauth_google',
            redirectUrl: '/',
            redirectUrlComplete: '/',
        });
    }

    const handleSignIn = async (e: any) => {
        e.preventDefault();
        setError('');
        await signIn
            ?.create({
                identifier: email,
                password: password,
                strategy: 'password',
            })
            .then(async (result) => {
                if (result.status === 'complete') {
                    await setSignInActive({ session: result.createdSessionId });
                    if (redirectUrl) {
                        return router.push(redirectUrl);
                    } else {
                        return router.push(`${process.env.NEXT_PUBLIC_BASE_URL}`);
                    }
                } else if (result.status === 'needs_first_factor') {
                    setPendingVerification(true);
                    sendEmailVerification();
                }
            })
            .catch((err) => {
                setError(err.errors[0].longMessage);
            });
    };

    async function sendEmailVerification() {
        setPendingVerification(true);
        signUp?.prepareEmailAddressVerification({
            strategy: 'email_code',
        });
    }

    // Modify resendVerification to start the timer
    const handleResendVerification = () => {
        if (canResend) {
            sendEmailVerification();
            setCanResend(false);
            setTimer(60); // Reset timer to 60 seconds
        }
    };

    async function handleSignUp() {
        setError('');
        if (!isSignUpLoaded) {
            return;
        }

        // console.log(email, password);
        await signUp
            ?.create({
                firstName: firstname,
                lastName: lastname,
                emailAddress: email,
                password: password,
            })
            .then(async (result) => {
                // console.log(result);
                if (result.status === 'complete') {
                    // console.log('complete');
                    setSignUpActive({ session: result.createdSessionId });
                    await fetch(`${process.env.CLERK_API_URL}/v1/accounts/users/${result.createdUserId}`, {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${process.env.CLERK_SECRET_KEY}`,
                        },
                        body: JSON.stringify({
                            firstName: firstname,
                            lastName: lastname,
                            public_metadata: {
                                profile: 'personal',
                            },
                        }),
                    });
                    await AddUserToDb(result);
                }

                if (result.status === 'missing_requirements') {
                    if (result.unverifiedFields.includes('email_address')) {
                        await signUp
                            ?.prepareEmailAddressVerification({
                                strategy: 'email_code',
                            })
                            .catch((err) => {
                                setError(err.errors[0].longMessage);
                            });

                        setPendingVerification(true);
                    }
                }
            })
            .catch((err) => {
                setError(err.errors[0].longMessage);
            });
    }

    // Update the digit at the specified index when the user types into an input field
    const handleInput = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const newCode = [...code];
        newCode[index] = e.target.value;
        setCode(newCode.join(''));

        const nextInput = inputsRef.current[index + 1];
        if (nextInput && e.target.value) {
            nextInput.focus();
        }
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>, index: number) => {
        e.preventDefault();
        // console.log('pasted');
        // console.log(e.clipboardData);
        const pastedData = e.clipboardData.getData('text/plain');
        const pastedArray = pastedData.split('');

        if (pastedArray.length === 6) {
            // Focus on the last input field after pasting
            setCode(pastedData);
            return;
            // setCode(pastedArray.join(''));
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        switch (e.key) {
            case 'Backspace':
                if (!e.currentTarget.value && index > 0) {
                    inputsRef.current[index - 1]?.focus();
                }
                break;
            case 'ArrowLeft':
                if (index > 0) {
                    inputsRef.current[index - 1]?.focus();
                }
                break;
            case 'ArrowRight':
                if (index < inputsRef.current.length - 1) {
                    inputsRef.current[index + 1]?.focus();
                }
                break;
            default:
        }
    };

    return (
        <div className="flex h-screen w-screen justify-center py-14">
            {!pendingVerification && (
                <Card className="w-screen max-w-lg p-6 h-fit">
                    <CardHeader className="flex-col items-center">
                        <div className="aspect-auto w-52">
                            <Logo />
                        </div>
                    </CardHeader>
                    <Tabs variant="solid" radius="md" fullWidth classNames={{ tabList: 'bg-zinc-200 ' }}>
                        <Tab key="login" title="Sign in">
                            <CardHeader className="flex-col items-start">
                                <div className="text-xl font-bold">Welcome to the Scam.SG Community</div>
                                <div className="">Sign in to your account to continue</div>
                            </CardHeader>
                            <CardBody className="flex-col gap-6">
                                {message && (
                                    <div className="text-center text-sm bg-primary-100 p-2 rounded-md">
                                        <p>{message}</p>
                                    </div>
                                )}
                                <div>
                                    <Input
                                        name="email"
                                        label="Email Address"
                                        labelPlacement="outside"
                                        classNames={{
                                            inputWrapper: 'px-0',
                                            input: 'px-4',
                                            base: 'rounded-md border-1 border-zinc-300',
                                        }}
                                        onValueChange={(e) => setEmail(e)}
                                        placeholder=" "
                                        required
                                    />
                                    <div className="mt-10">
                                        <Input
                                            name="password"
                                            label="Password"
                                            labelPlacement="outside"
                                            classNames={{
                                                inputWrapper: 'px-0',
                                                input: 'px-4',
                                                base: 'rounded-md border-1 border-zinc-300',
                                            }}
                                            placeholder=" "
                                            onValueChange={(e) => setPassword(e)}
                                            endContent={
                                                <Button className="bg-transparent focus:outline-none" isIconOnly onClick={toggleVisibility}>
                                                    {isVisible ? (
                                                        <EyeSlashIcon className="pointer-events-none text-xl text-zinc-500" />
                                                    ) : (
                                                        <EyeIcon className="pointer-events-none text-xl text-zinc-500" />
                                                    )}
                                                </Button>
                                            }
                                            type={isVisible ? 'text' : 'password'}
                                            required
                                        />
                                        {/* error */}
                                        {error && (
                                            <div className="flex flex-row items-center py-2">
                                                <InfoIcon className="fill-primary" />
                                                <div className="text- text-primary">{error}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <Button fullWidth onClick={handleSignIn} size="md" radius="sm" className="bg-primary text-xs font-semibold text-white">
                                    CONTINUE
                                </Button>
                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </Tab>
                        <Tab value="2" title="Sign up">
                            <CardHeader className="flex-col items-start">
                                <div className="text-xl font-bold">Join the Scam.SG Community</div>
                                <p>Create an account to get started</p>
                            </CardHeader>
                            <CardBody className="flex-col gap-6">
                                {message && (
                                    <div className="text-center text-sm text-gray-700 bg-primary-100 p-2 rounded-md">
                                        <p>{message}</p>
                                    </div>
                                )}
                                <div>
                                    <div className="flex flex-col gap-2 md:flex-row">
                                        <Input
                                            name="firstname"
                                            label="First Name"
                                            labelPlacement="outside"
                                            classNames={{
                                                inputWrapper: 'px-0',
                                                input: 'px-4',
                                                base: 'rounded-md border-1 border-zinc-300',
                                            }}
                                            onChange={(e) => setFirstname(e.target.value)}
                                            placeholder=" "
                                            required
                                        />
                                        <div className="md:mt-10" />
                                        <Input
                                            name="lastname"
                                            label="Last Name"
                                            labelPlacement="outside"
                                            classNames={{
                                                inputWrapper: 'px-0',
                                                input: 'px-4',
                                                base: 'rounded-md border-1 border-zinc-300',
                                            }}
                                            onChange={(e) => setLastname(e.target.value)}
                                            placeholder=" "
                                            required
                                        />
                                    </div>
                                    <div className="mt-10">
                                        <Input
                                            name="email"
                                            label="Email Address"
                                            labelPlacement="outside"
                                            classNames={{
                                                inputWrapper: 'px-0',
                                                input: 'px-4',
                                                base: 'rounded-md border-1 border-zinc-300',
                                            }}
                                            onValueChange={(e) => setEmail(e)}
                                            placeholder=" "
                                            required
                                        />
                                    </div>
                                    <div className="mt-10">
                                        <Input
                                            name="password"
                                            label="Password"
                                            labelPlacement="outside"
                                            classNames={{
                                                inputWrapper: 'px-0',
                                                input: 'px-4',
                                                base: 'rounded-md border-1 border-zinc-300',
                                            }}
                                            placeholder=" "
                                            onValueChange={(e) => setPassword(e)}
                                            endContent={
                                                <Button className="bg-transparent focus:outline-none" isIconOnly onClick={toggleVisibility}>
                                                    {isVisible ? (
                                                        <EyeSlashIcon className="pointer-events-none text-xl text-zinc-500" />
                                                    ) : (
                                                        <EyeIcon className="pointer-events-none text-xl text-zinc-500" />
                                                    )}
                                                </Button>
                                            }
                                            type={isVisible ? 'text' : 'password'}
                                            required
                                        />
                                        {/* error */}
                                        {error && (
                                            <div className="flex flex-row items-center py-2">
                                                <div>
                                                    <InfoIcon size={24} className="fill-primary" />
                                                </div>
                                                <div className="ml-1 text-sm text-primary">{error}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <Button fullWidth onClick={handleSignUp} size="md" radius="sm" className="bg-primary text-xs font-semibold text-white">
                                    CONTINUE
                                </Button>
                            </CardBody>
                        </Tab>
                    </Tabs>
                </Card>
            )}

            {pendingVerification && (
                <Card className="w-screen max-w-xl p-6">
                    <CardHeader className="flex-col items-start">
                        <div className="text-xl font-bold">Verify Email</div>
                        <p className="text-default">Please enter the verification code sent to your email address:</p>
                    </CardHeader>
                    <CardBody className="flex flex-col ">
                        <div className="mx-auto flex flex-row items-center justify-center gap-3">
                            {Array.from({ length: 6 }).map((_, index) => (
                                <Input
                                    key={index}
                                    type="tel"
                                    maxLength={1}
                                    radius="md"
                                    pattern="[0-9]"
                                    classNames={{
                                        input: 'text-center text-lg',
                                        inputWrapper: 'w-[50px] h-[50px] border-1 border-zinc-300 shadow-md',
                                    }}
                                    onChange={(e) => handleInput(e, index)}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    onPaste={(e) => handlePaste(e, index)}
                                    ref={(el) => (inputsRef.current[index] = el)}
                                />
                            ))}
                        </div>
                        {error && <p className="text-red-500">{error}</p>}
                    </CardBody>
                    <CardFooter>
                        <div className="flex flex-col">
                            <span className="text-sm text-default">Didn&apos;t receive an email? {!canResend && <span>Resend in {timer}</span>} </span>
                            <Link
                                onClick={handleResendVerification}
                                isDisabled={!canResend}
                                className="ml-1 cursor-pointer bg-transparent text-sm hover:text-primary"
                            >
                                Resend email
                            </Link>
                        </div>
                    </CardFooter>
                </Card>
            )}
        </div>
    );
}

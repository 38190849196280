/**
 * v0 by Vercel.
 * @see https://v0.dev/t/2xlaDwOE8U8
 */

import { Button } from "@heroui/react"


export default function EmailVerified() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-800">
      <CheckIcon className="h-24 w-24 text-green-500 dark:text-green-400" />
      <h1 className="text-3xl font-bold mt-4">Email Verified</h1>
      <p className="text-gray-500 dark:text-gray-400 mt-2 text-center">
        Congratulations! Your email has been successfully verified.
      </p>
      <Button className="mt-8" href="/" >Continue</Button>
    </div>
  )
}

function CheckIcon(props:any) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <polyline points="20 6 9 17 4 12" />
    </svg>
  )
}
